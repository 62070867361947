<template>
    <span class="pill">
        <slot></slot>
    </span>
</template>

<script>
export default {
    name: 'Pill',
}
</script>

<style scoped lang="scss">
.pill {
    display: inline-block;
    margin-top: 4px;
    margin-right: 8px;
    padding: 6px 12px;
    background-color: $color-gray-light-new;
    border-radius: 20% / 50%;
    font-weight: 600;
    font-size: 14px;
}
</style>
