<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M9.6,7.724A5.985,5.985,0,0,0,2,13.5V19A1.5,1.5,0,0,1,.5,20.5h15A1.5,1.5,0,0,1,14,19V13.686"
        />

        <path class="a" d="M9.415,22.5a1.5,1.5,0,0,1-2.829,0" />

        <path class="a" d="M8 5.501L8 7.501" />

        <path
            class="a"
            d="M11.500 6.502 A6.000 6.000 0 1 0 23.500 6.502 A6.000 6.000 0 1 0 11.500 6.502 Z"
        />

        <path class="a" d="M19.5 6.502L17.5 6.502 17.5 3.502" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'AlarmBellIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
