<template>
    <div class="l-inline l-gap-3 notification-details-card" v-on="$listeners">
        <div class="notification-details-card__icon">
            <alarm-bell-sleep-icon v-if="snoozedUntil" width="28" height="28" />

            <alarm-bell-icon v-else width="28" height="28" />
        </div>

        <div
            class="l-full-width l-stack l-gap-1 notification-details-card__info"
        >
            <div>
                <h2 class="l-inline l-center-v">
                    <span>{{ rule.name }}</span>

                    <badge v-if="snoozedUntil">
                        {{ $t('snoozedUntil', [snoozedUntil]) }}
                    </badge>

                    <active-inactive-badge v-else :active="rule.active">
                        {{ $t(rule.active ? 'active' : 'inactive') }}
                    </active-inactive-badge>

                    <badge v-if="rule.delay > 0">
                        {{ $t('delayed') }}
                    </badge>
                </h2>
            </div>

            <p class="notification-details-card__description">
                <i>{{ $t('if') }}</i>

                <span
                    v-for="(condition, i) in rule.conditions.conditions"
                    :key="'condition-' + i"
                >
                    <b v-if="condition.type === 'geofence'">
                        {{ $t(condition.position) }}
                        {{ getLocationName(condition.locationId) }}
                    </b>

                    <b v-else-if="condition.type === 'state'">
                        {{ $t(condition.value) }}
                        {{ $t(condition.state ? 'isOn' : 'isOff') }}
                    </b>

                    <b v-else-if="condition.type === 'offline'">
                        {{
                            $t('offlinefor', {
                                amount:
                                    condition.seconds /
                                    timeOptions[condition.user_unit],
                                unit: condition.user_unit,
                            })
                        }}
                    </b>

                    <b v-else-if="condition.type === 'threshold'">
                        {{ $t('shared.measurements.' + condition.value) }}
                        {{ condition.operator }}
                        {{
                            condition.value === 'fill_level'
                                ? condition.threshold * 100
                                : condition.threshold
                        }}
                        {{ units[condition.value] }}
                    </b>

                    <i v-if="i < rule.conditions.conditions.length - 1">
                        {{ $t(rule.conditions.matchMode + 'MatchMode') }}
                    </i>
                </span>

                <i>{{ $t('then') }}</i>

                <span
                    v-for="(value, group, i) in notificationChannelsGrouped"
                    :key="'channel-' + group"
                >
                    <i v-if="i > 0">
                        {{ $t('allMatchMode') }}
                    </i>

                    <b>
                        {{ $t('channel-' + group) }}
                    </b>

                    <i>({{ value }})</i>
                </span>

                <i
                    v-if="
                        Object.keys(notificationChannelsGrouped).length &&
                            (rule.alert_by_email || rule.alert_by_sms)
                    "
                >
                    {{ $t('allMatchMode') }}
                </i>

                <b v-if="rule.alert_by_email"> {{ $t('email to me') }}</b>

                <i v-if="rule.alert_by_email && rule.alert_by_sms">
                    {{ $t('allMatchMode') }}
                </i>

                <b v-if="rule.alert_by_sms"> {{ $t('sms to me') }}</b>
            </p>

            <p>
                <pill v-if="rule.assets.length || rule.all_assets">
                    {{
                        $tc(
                            `shared.assetCount`,
                            rule.assets.length || trackers.length
                        )
                    }}
                </pill>

                <pill v-if="rule.locations.length">
                    {{ $tc('locations', rule.locations.length) }}
                </pill>

                <pill v-if="rule.asset_types.length">
                    {{ $tc('types', rule.asset_types.length) }}
                </pill>
            </p>
        </div>

        <div class="l-inline l-gap-3 notification-details-card__actions">
            <div v-if="!snoozedUntil" @click="$event.stopPropagation()">
                <toggle-button
                    class="redesigned"
                    :value="rule.active"
                    sync
                    css-colors
                    :width="40"
                    :height="24"
                    :speed="150"
                    @input="$emit('toggle')"
                />
            </div>

            <div>
                <a @click.stop="handleContextMenuOpen">
                    <icon-button>
                        <alarm-bell-timer-icon width="24" height="24" />
                    </icon-button>
                </a>

                <context-menu
                    ref="contextMenu"
                    :element-id="`notificationDetailsCardContextMenu${_uid}`"
                    :options="snoozeOptions"
                    @option-clicked="handleContextMenuClick"
                />
            </div>

            <div>
                <a @click.stop="$emit('delete')">
                    <icon-button>
                        <trash-bin-icon width="24" height="24" />
                    </icon-button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import ContextMenu from 'vue-simple-context-menu'
import { ToggleButton } from 'vue-js-toggle-button'

import { measurementHelper } from '@/utils'

import ActiveInactiveBadge from '@/components/redesigned/ActiveInactiveBadge'
import AlarmBellIcon from '../icons/AlarmBellIcon'
import AlarmBellSleepIcon from '../icons/AlarmBellSleepIcon'
import AlarmBellTimerIcon from '../icons/AlarmBellTimerIcon'
import Badge from '@/components/redesigned/Badge'
import IconButton from '@/components/IconButton'
import Pill from '@/components/redesigned/Pill'
import TrashBinIcon from '../icons/TrashBinIcon'

export default {
    name: 'NotificationDetailsCard',
    components: {
        ActiveInactiveBadge,
        AlarmBellIcon,
        AlarmBellSleepIcon,
        AlarmBellTimerIcon,
        Badge,
        ContextMenu,
        IconButton,
        Pill,
        ToggleButton,
        TrashBinIcon,
    },
    props: {
        rule: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            units: measurementHelper.units,
            timeOptions: {
                h: 60 * 60,
                min: 60,
            },
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('locations', ['getLocationsSortedByName']),
        notificationChannelsGrouped() {
            const email = this.rule.notification_channels
                .filter(item => item.type === 'EMAIL')
                .map(item => item.target)
                .join(', ')
            const sms = this.rule.notification_channels
                .filter(item => item.type === 'SMS')
                .map(item => item.target)
                .join(', ')

            return {
                ...(email.length && { email }),
                ...(sms.length && { sms }),
            }
        },
        snoozeOptions() {
            if (this.snoozedUntil) {
                return [{ name: this.$t('activate'), value: 0 }]
            }

            return [
                { name: this.$t('snoozeOption5m'), value: 1000 * 60 * 5 },
                { name: this.$t('snoozeOption1h'), value: 1000 * 60 * 60 },
                { name: this.$t('snoozeOption4h'), value: 1000 * 60 * 60 * 4 },
                { name: this.$t('snoozeOption1d'), value: 1000 * 60 * 60 * 24 },
                {
                    name: this.$t('snoozeOption1w'),
                    value: 1000 * 60 * 60 * 24 * 7,
                },
            ]
        },
        snoozedUntil() {
            const datetime = moment(this.rule.valid_after)
            return datetime > moment.now()
                ? datetime.format('DD.MM.YYYY HH:mm')
                : null
        },
    },
    methods: {
        getLocationName(locationId) {
            return this.getLocationsSortedByName.find(
                location => location.id === locationId
            )?.name
        },
        handleContextMenuOpen(e) {
            setTimeout(() => {
                this.$refs.contextMenu.showMenu(e)
            })
        },
        handleContextMenuClick({ option: { value } }) {
            this.$emit('snooze', value)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "activate": "Activate",
        "active": "Active",
        "allMatchMode": "and",
        "anyMatchMode": "or",
        "channel-email": "Send E-Mail",
        "channel-sms": "Send SMS",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "delayed": "Delayed",
        "email to me": "E-Mail to me",
        "if": "If",
        "inactive": "Inactive",
        "inside": "Inside",
        "isOff": "is Off",
        "isOn": "is On",
        "locations": "{n} Location | {n} Locations",
        "offlinefor": "offline for {amount} {unit}",
        "outside": "Outside",
        "sms to me": "SMS to me",
        "snoozeOption1d": "1 day",
        "snoozeOption1h": "1 hour",
        "snoozeOption1w": "1 week",
        "snoozeOption4h": "4 hours",
        "snoozeOption5m": "5 minutes",
        "snoozedUntil": "Snoozed until {0}",
        "then": "then",
        "types": "{n} Type | {n} Types"
    },
    "de": {
        "activate": "Aktivieren",
        "active": "Aktiv",
        "allMatchMode": "und",
        "anyMatchMode": "oder",
        "channel-email": "E-Mail senden",
        "channel-sms": "SMS senden",
        "d1": "Kanal 1",
        "d2": "Kanal 2",
        "delayed": "Verzögert",
        "email to me": "E-Mail an mich",
        "if": "Wenn",
        "inactive": "Inaktiv",
        "inside": "Innerhalb",
        "isOff": "aus",
        "isOn": "ein",
        "locations": "{n} Standort | {n} Standorte",
        "offlinefor": "offline für {amount} {unit}",
        "outside": "Ausserhalb",
        "sms to me": "SMS an mich",
        "snoozeOption1d": "1 Tag",
        "snoozeOption1h": "1 Stunde",
        "snoozeOption1w": "1 Woche",
        "snoozeOption4h": "4 Stunden",
        "snoozeOption5m": "5 Minuten",
        "snoozedUntil": "Pausiert bis {0}",
        "then": "dann",
        "types": "{n} Typ | {n} Typen"
    },
    "it": {
        "activate": "Activate",
        "active": "Attivo",
        "allMatchMode": "e",
        "anyMatchMode": "o",
        "channel-email": "Inviare E-Mail",
        "channel-sms": "Inviare SMS",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "delayed": "Ritardato",
        "email to me": "E-Mail a me",
        "if": "Se",
        "inactive": "Inattivo",
        "inside": "All'interno di",
        "isOff": "è off",
        "isOn": "è on",
        "locations": "{n} luogo | {n} luoghi",
        "offlinefor": "offline per {amount} {unit}",
        "outside": "All'esterno di",
        "sms to me": "SMS a me",
        "snoozeOption1d": "1 giorno",
        "snoozeOption1h": "1 ore",
        "snoozeOption1w": "1 settimana",
        "snoozeOption4h": "4 ore",
        "snoozeOption5m": "5 minuti",
        "snoozedUntil": "Sonnecchiato fino a {0}",
        "then": "Poi",
        "types": "{n} tipo | {n} tipi"
    }
}
</i18n>

<style lang="scss" scoped>
.notification-details-card {
    padding: 24px 28px 16px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-size: 14px;
    color: $color-text-new;

    &__description {
        i {
            margin-right: 0.1rem;
        }
    }

    h2 {
        flex-wrap: wrap;
        font-weight: 700;
        font-size: 16px;
        color: $color-text-new;

        & > * {
            margin-right: 8px;
        }
    }

    a {
        svg {
            color: $color-primary-light;
        }
    }

    @include respond-to('for-small-mobile-only') {
        flex-wrap: wrap;

        &__icon {
            flex: 1;
        }

        &__info {
            flex: 1 0 100%;
            order: 2;
            margin: 8px 0 0;
        }

        &__actions {
            & > * {
                margin-left: 16px;
            }
        }
    }
}
</style>
